import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
// import {map} from 'rxjs/operators';
import { Account, AccountGoal, AccountGoalAutoSave } from '../models/account';
import { Profile } from '@orion/models';


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private apiUrl = `${environment.serverUrl}/api/v1/accounts`;
  private apiUrlGuest = `${environment.serverUrl}/api/v1/guest`;
  private apiUrlRoot = `${environment.serverUrl}/api/v1`;

  constructor(private http: HttpClient) {
  }


  // getAllIndividualAccounts(): Observable<Account[]> {
  //   console.log(this.apiUrl);
  //   return this.http.get<Account[]>(`${this.apiUrl}/individual`);
  // }

  getAllIndividualAccounts(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}/individual`, { params });
  }


  getAllInstitutionalAccounts(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}/institution/list`, { params });
  }

  getAutoSaveByGoalId(activeAccountGoalId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrlRoot}/autosave/account-goal-autosaves/${activeAccountGoalId}`);
  }

  getInstitutionAccountById(id): Observable<any> {
    const options = {
      params: new HttpParams(),
      timeout: 180000
    };
    return this.http.get<any>(`${this.apiUrl}/institution/${id}`, options);
  }

  getIndividualAccountById(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/individual/details/${id}`);
  }

  getAccountGoal(accountGoalId: string): Observable<AccountGoal> {
    return this.http.get<AccountGoal>(`${this.apiUrl}/account-goal/${accountGoalId}`);
  }

  getAccountGoalAutoSave(accountGoalId: string): Observable<AccountGoalAutoSave> {
    return this.http.get<AccountGoalAutoSave>(`${this.apiUrlRoot}/users/goal-autosave-details?accountGoalId=${accountGoalId}`);
  }

  getInvestmentStrategy(riskScore: string, goalCode: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrlRoot}/guest/profiler/evaluate?riskScore=${riskScore}&goalCode=${goalCode}`);
  }

  createOrUpdateAllocationsOfGoal(riskTolerance: string, accountGoalId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrlRoot}/accounts/risk-profile-update?riskTolerance=${riskTolerance}&accountGoalId=${accountGoalId}`);
  }

  updateExistingAllocationsOfGoal(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrlRoot}/accounts/allocations-update`, data);
  }

  // deactivateGoal(id: any) {
  //   return this.http.get<any>(`${this.apiUrl}/goal/deactivate/${id}`);
  // }

  addMembers(formData: any, accountId): Observable<any> {
    return this.http.post<any>(`${this.apiUrlRoot}/dashboard/add-linked-members-phones/${accountId}`, formData);
  }

  addRepresentatives(formData: any, accountId): Observable<any> {
    return this.http.post<any>(`${this.apiUrlRoot}/dashboard/add-representative-phones/${accountId}`, formData);
  }

  getInstitutionDocument(id: string): Observable<any> {
    return this.http.get<any>(`${environment.serverUrl}api/v1/guest/institution-document?accountId=${id}`);
  }

  getInstitutionMembers(id: string): Observable<any> {
    return this.http.get<any>(`${environment.serverUrl}/institution/institution-members/${id}`);
  }

  //
  addMemberPhoneNumbers(sheetToJson: any, accountId) {
    return this.http.post<any>(`${this.apiUrlRoot}/institution/add-linked-members-phones/${accountId}`, sheetToJson);
  }

  initLock(accountGoalId: string) {
    return this.http.get<any>(`${this.apiUrl}/account-goal/${accountGoalId}/unlock-withdrawal`);
  }

  updateInstitutionalInformation(formData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/institution/update-institutional-info`, formData);
  }

  getInstitutionalImage(accountId: string): Observable<Blob> {
    return this.http.get(
      `${this.apiUrlGuest}/orion-institutional-document?accountId=${accountId}`,
      { responseType: 'blob' }
    );
  }

  manageInstitutionRights(updatedFormInput: { accountId: string; right: any; memberId: any }): Observable<any> {
    if (updatedFormInput.right === 'member') {
      return this.http.post<any>(`${this.apiUrl}/institution/manage-member-rights`, updatedFormInput);
    } else {
      return this.http.post<any>(`${this.apiUrl}/institution/manage-representative-rights`, updatedFormInput);
    }
  }

  checkSponsorAccountNotLinkedInTransactions(accountId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/institution/check-sponsor-account-not-linked/${accountId}`);
  }

  updateSponsorAccountNotLinkedInTransactions(accountId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/institution/update-sponsor-account-not-linked/${accountId}`);
  }

  removeMemberFromInstitutionGroup(member: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/institution/remove-member`, member);
  }
}
