import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {saveAs} from "file-saver";

import {Profile} from '../../models';
import heic2any from "heic2any";
import {UtilitiesService} from '@orion/utilities/utilities.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {
  @Output() closeWindow = new EventEmitter();
  @Input() profileEmail: string;
  @Input() accountId: string;
  @Input() imageType: string;
  @Input() fileType: string;
  @Input() details: Profile;
  @Input() fileUrl: any;
  @Input() idURLFile: any;
  fileName: string;
  isLoading = true;
  liveImageUrl = environment.serverUrl;

  constructor(private profileService: ProfileService,
              private sanitizer: DomSanitizer,
              private utitlities: UtilitiesService) { }

  closeFilePreview() {
    this.closeWindow.emit();
  }


  ngOnInit(): void {
    // this.convertHeicToJpeg(this.fileUrl);
    // if (this.imageType === 'id' || this.imageType === 'passport') {
    //   // this.idURLFile = `${this.liveImageUrl}/api/v1/guest/idphoto/${this.profileEmail}`
    //   // this.fileUrl = `${this.liveImageUrl}/api/v1/guest/passportphoto/${this.profileEmail}`
    //   // this.fileUrl = `${environment.serverUrl}/api/v1/guest/idphoto/${this.profileEmail}`;
    //
    //   this.isLoading = false;
    //   if(this.details.identificationFile.toLowerCase().includes(".heic")){
    //        // this.convertHeicToJpeg(this.idURLFile);
    //       }
    //   if(this.details.passportFile.toLowerCase().includes(".heic")){
    //     this.convertHeicToJpeg(this.fileUrl);
    //   }
    // }
    // else if (this.imageType === 'passport') {
    //   this.fileUrl = `${this.liveImageUrl}/api/v1/guest/passportphoto/${this.profileEmail}`
    //   // this.fileUrl = `${environment.serverUrl}/api/v1/guest/passportphoto/${this.profileEmail}`;
    //   this.isLoading = false;
    //   if(this.details.passportFile.toLowerCase().includes(".heic")){
    //         this.convertHeicToJpeg(this.fileUrl);
    //       }
    // }
    // else if (this.imageType === 'certificate') {
    //  // this.fileUrl = `${environment.serverUrl}/api/v1/guest/orion-institutional-document?accountId=${this.accountId}`;
    //  //  this.profileService.getInstitutionDocument(this.accountId).subscribe({
    //  //    next: (resp) => {
    //  //      this.fileUrl = URL.createObjectURL(resp);
    //  //    }
    //  //  });
      this.isLoading = false;
    // }
  }
  //
  // downloadFile(fileType: string) {
  //   if (fileType === 'Image') {
  //     this.fileName = this.profileEmail + '-' + this.imageType + '.png';
  //   }else if (fileType === 'PDF') {
  //     this.fileName = this.profileEmail + '-' + this.imageType + '.pdf';
  //   }
  //   this.profileService.downloadFile(this.fileUrl).subscribe({
  //     next: (response: Blob) => {
  //       const blob: any = new Blob([response], {type: 'text/json; charset=utf-8'});
  //       saveAs(blob, this.fileName);
  //     },
  //     error: (error) => {
  //       alert('Error downloading file.' + error);
  //     },
  //   });
  // }


  // async convertHeicToJpeg(fileUrl : any,) {
  //   let blobRes = await fetch(fileUrl);
  //
  //   // convert response to blob
  //   let blob = await blobRes.blob();
  //
  //   //convert to JPG
  //   let coversionResult = await heic2any({blob: blob, toType: "image/jpg", quality:0.9})
  //   console.log(coversionResult);
  //
  //   // convert blob to url
  //     // @ts-ignore
  //   this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(coversionResult));
  // }
}
