<app-single-profile *ngIf="showProfile" [profileId]="profileId" (closeProfile)="hideProfile()"></app-single-profile>

<div class="u-justify nb-20 mt-2">
  <h1 class="page-title">AUM Dashboard</h1>
  <form class="page-filters" [formGroup]="filtersForm">
    <!-- Date Filter  -->
    <div class="page-action">
      <mat-form-field appearance="fill">
        <mat-label>
          Date Filter
        </mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dateFilter" (dateChange)="applyDateFilter($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

      </mat-form-field>
    </div>
    <!-- Advisor Filter  -->
    <div class="page-action">
      <mat-form-field appearance="fill">
        <mat-label>Advisor</mat-label>
        <mat-select formControlName="advisor" (selectionChange)="applyAdvisorFilter($event)">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let advisor of advisors" value="{{ advisor.id }}">
            {{advisor.firstName}} {{advisor.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Fund Status Filter  -->
    <div class="page-action">
      <mat-form-field appearance="fill">
        <mat-label>Funded Status</mat-label>
        <mat-select formControlName="fundedStatus" (selectionChange)="applyFundedStatusFilter($event)">
          <mat-option value="">All</mat-option>
          <mat-option value="FUNDED">Funded</mat-option>
          <mat-option value="NOTFUNDED">Not Funded</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>

<mat-grid-list cols="3" gutterSize="20" rowHeight="150px">
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <mat-card-content>
        <div class="d-flex mb-3">
          <div class="">
            <h3 class="mat-card-title">Total AUM</h3>
          </div>
          <div class="ms-auto">
            <div class="cont" align="center">
              <mat-icon>money</mat-icon>
            </div>

          </div>
        </div>
        <div>
          <h1 class="mat-card-title-2">{{tenantCurrency}} {{dataSource.totalAUM | async | number: '1.0-0'}}</h1>
        </div>
      </mat-card-content>

    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <mat-card-content>
        <div class="d-flex mb-3">
          <div class="">
            <h3 class="mat-card-title">Total Profiles</h3>
          </div>
          <div class="ms-auto">
            <div class="cont" align="center">
              <mat-icon>group</mat-icon>
            </div>

          </div>
        </div>
        <div>
          <h1 class="mat-card-title-2">{{dataSource.resultsLength | async | number: '1.0-0'}}</h1>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <mat-card-content>
        <div class="d-flex mb-3">
          <div class="">
            <h3 class="mat-card-title">Average AUM Per Profile</h3>
          </div>
          <div class="ms-auto">
                <div class="cont" align="center">
                  <mat-icon>bar_chart</mat-icon>
                </div>

          </div>
        </div>
            <div>
              <h1 class="mat-card-title-2">{{tenantCurrency}} {{dataSource.averageAum | async | number: '1.0-0'}}</h1>
            </div>
      </mat-card-content>

    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<div class="pt-lg-2">

  <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
    <div class="">

    <table mat-table [dataSource]="dataSource" matSort matSortActive="dateCreated" matSortDisableClear
    matSortDirection="desc">

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>

    <!-- dateCreated Column -->
    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Joined</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dateCreated | date }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Names</th>
      <td mat-cell *matCellDef="let element">
        <div class="u-profile">
          <figure class="u-profile-photo {{ element.kycStatus }}" [style.backgroundImage]="
            'url(' +  element.avatarUrl + ')'
          "></figure>
          <a>{{ element.name | titlecase }}</a>
        </div>
      </td>
    </ng-container>

    <!-- Advisor Column -->
    <ng-container matColumnDef="advisor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Advisor</th>
      <td mat-cell *matCellDef="let element"> {{element.advisor}} </td>
    </ng-container>

    <!-- Number of Accounts Column -->
    <ng-container matColumnDef="numberOfAccounts">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No. of Accounts</th>
      <td mat-cell *matCellDef="let element"> {{element.numberOfAccounts}} </td>
    </ng-container>

    <!-- Profile Total AUM Column -->
    <ng-container matColumnDef="profileTotalAUM">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total AUM</th>
      <td mat-cell *matCellDef="let element"> {{tenantCurrency}} {{element.profileTotalAUM | number: '1.0-0'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showProfileDetail(row.id)"></tr>
  </table>

  <mat-paginator [length]="dataSource.resultsLength | async" [pageSize]="50" showFirstLastButtons
  [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>


<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
