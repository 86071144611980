<div class="desktop-only">
  <ng-container *ngIf="selectCountry" >
    <app-country-selector >

    </app-country-selector>
  </ng-container>
  <router-outlet></router-outlet>
</div>


<div class="non-desktop-message">
  <div class="card mx-2">
    <div class="card-body">
      <br/>
      <div class="text-center">
        <div class="d-flex justify-content-center mb-4">
          <img style="width: 100px" src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MjAgNzIwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzAxMGYyN308L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSIjMDBlMmMxIiBkPSJNMCAwaDcyMHY3MjBIMHoiLz48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0zMjUuODMgMzMxLjA4YTExLjMyIDExLjMyIDAgMDAwLTIyLjY0aC01NS4xOWExMSAxMSAwIDAwLTggMy4zNCAxMC45IDEwLjkgMCAwMC0zLjI4IDh2MTAxLjUzYTEwLjg4IDEwLjg4IDAgMDAzLjI2IDggMTEgMTEgMCAwMDggMy4zNmg1NS4xOWExMS4zMiAxMS4zMiAwIDAwNy45MS0xOS4zNCAxMS4wNyAxMS4wNyAwIDAwLTcuOTEtMy4yMWgtNDMuOTJ2LTI4LjQ2aDIxLjY5YTExLjI4IDExLjI4IDAgMTAwLTIyLjU1aC0yMS42OXYtMjh6TTQ1Mi4zMSAzMDguMjRBMTEuMzIgMTEuMzIgMCAwMDQ0MSAzMTkuNDdWMzgxYy04LjU0LTE1Ljc5LTIxLjItMzguMTItMzcuNzEtNjYuNTQtMi43Ni00LjY1LTYuNTYtNS42Mi05LjI4LTUuNjJhMTMuNjUgMTMuNjUgMCAwMC0zLjQuNDUgMTAuNTggMTAuNTggMCAwMC04LjM2IDEwLjg3djEwMS4xMWExMS4yOCAxMS4yOCAwIDEwMjIuNTUgMHYtNjEuMzljOC41NiAxNi4xMiAyMS4yNSAzOC42NiAzNy43NyA2Ny4xNSAyLjc2IDQuNjMgNi41MiA1LjYgOS4yIDUuNmExMy43OCAxMy43OCAwIDAwMy40Mi0uNDQgMTAuNTggMTAuNTggMCAwMDguMzYtMTAuODhWMzE5LjQ3YTExLjA1IDExLjA1IDAgMDAtMy4yOC04IDEwLjgxIDEwLjgxIDAgMDAtOC0zLjI4TTE1Ni44IDM2Ni4yNWExMS4xNyAxMS4xNyAwIDAwOCAzLjI5aC4yYTExLjIxIDExLjIxIDAgMDA4LTMuM0wyMTIuMTYgMzI3YTExLjI2IDExLjI2IDAgMTAtMTUuOTQtMTUuOTJsLTMxLjM0IDMxLjM0LTMxLjM1LTMxLjM0QTExLjI2IDExLjI2IDAgMDAxMTcuNTkgMzI3ek0xNzMgMzc0LjE5YTExLjE5IDExLjE5IDAgMDAtOC0zLjI5aC0uMTlhMTEuMTcgMTEuMTcgMCAwMC04IDMuM2wtMzkuMjEgMzkuMmExMS4yNyAxMS4yNyAwIDAwMTUuOTQgMTUuOTNMMTY0Ljg4IDM5OGwzMS4zNSAzMS4zNWExMS4yNiAxMS4yNiAwIDEwMTUuOTMtMTUuOTN6TTU1Ny43OCAzMDhhNDguMzQgNDguMzQgMCAwMC00OC4yOSA0OC4zdjI4YTQ4LjI5IDQ4LjI5IDAgMDA5Ni41OCAwdi0yOGE0OC4zNSA0OC4zNSAwIDAwLTQ4LjI5LTQ4LjNtLTI1LjY4IDQ3LjFhMjUuNjggMjUuNjggMCAwMTUxLjM2IDB2MzAuNDVhMjUuNjggMjUuNjggMCAwMS01MS4zNiAwek01NDYuMTUgMzAzLjI2aDIzLjI2YTguODQgOC44NCAwIDAwMC0xNy42N2gtMjMuMjZhOC44NCA4Ljg0IDAgMDAwIDE3LjY3Ii8+PC9zdmc+"/>
        </div>
        <h1 class="mb-2" style="font-size: 20px">Access Denied</h1>
        <p>This application is only available on desktop devices</p>
        <div class="mt-4">
          <a href="mailto:support@myxeno.com" class="btn btn-primary">Contact Admin</a>
        </div>
      </div>
      <br/>
    </div>
  </div>


</div>
