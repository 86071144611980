<!-- Single goal dash -->
<app-single-goal (closeGoal)="closeGoalDetails()" *ngIf="singleGoal" [accountId]="accountId"
  [tenantCurrency]="tenantCurrency" [profileDetails]="profileDetails" [accountType]="accountType"
  [accountGoalId]="accountGoalId" [zIndex]="2">
</app-single-goal>

<!-- Reset PIN Modal -->
<app-reset-pin (closeWindow)="closeResetPINWindow()" *ngIf="ResetPINView"
  [profileInformation]="profileDetails"></app-reset-pin>
<!-- Edit visual ID -->

<app-edit-visual-id (close)="editIDView = false" *ngIf="editIDView"
  [names]="profileDetails.firstName + ' ' + profileDetails.lastName" [personalInfo]="profileDetails"
  [userName]="profileDetails.email"></app-edit-visual-id>
<edit-profile (close)="editProfileView = false" *ngIf="editProfileView"></edit-profile>
<app-edit-profile-income-source (close)="editProfileIncomeSource = false" *ngIf="editProfileIncomeSource"
  [profileEmail]="profileDetails.email" [profileInformation]="profileDetails">
</app-edit-profile-income-source>
<app-edit-profile-address (close)="editProfileAddress = false" *ngIf="editProfileAddress"
  [profileInformation]="profileDetails">
</app-edit-profile-address>

<!-- View ID & Passport -->
<app-file-preview (closeWindow)="closeFilePreview()" *ngIf="idPreview" [details]="profileDetails"
  [fileUrl]="passportUrl" [idURLFile]="idUrl" [fileType]="fileType" [imageType]="imageType"
  [profileEmail]="profileDetails.email">
</app-file-preview>

<!-- Edit personal info -->
<app-personal-information (closeWindow)="closePersonalEditWindow()" *ngIf="editPersonalView"
  [profileInformation]="profileDetails" [isProspect]="isProspect">
</app-personal-information>

<!-- Edit Bank Details -->
<app-edit-profile-bank-details (close)="editProfileBank = false" *ngIf="editProfileBank" [accountId]="accountId"
  [profileBankInformation]="bankDetails" [userId]="profileDetails.email">
</app-edit-profile-bank-details>

<!-- Edit Beneficiary -->
<app-edit-profile-account-beneficiary (close)="editProfileAccountBeneficiary = false"
  *ngIf="editProfileAccountBeneficiary" [accountId]="accountId"
  [profileAccountBeneficiaryInformation]="beneficiaryDetails" [userId]="profileDetails.email">
</app-edit-profile-account-beneficiary>

<!-- Create Advisor -->
<app-create-advisor (close)="reloadProfilesTable()" *ngIf="createProfileAdvisor" [advisorInfo]="advisorDetails"
  [userId]="profileDetails.email">
</app-create-advisor>

<!-- Add Advisor Note -->
<app-user-add-note (close)="reloadProfilesTable()" *ngIf="addNote" [userId]="userId">
</app-user-add-note>

<!-- View Advisor Note -->
<app-view-advisor-note (close)="reloadProfilesTable()" *ngIf="showViewNotesModal" [userNote]="userNote"
  [userId]="userId">
</app-view-advisor-note>

<app-edit-advisor (close)="reloadProfilesTable()" *ngIf="editProfileAdvisor" [advisorInfo]="advisorDetails"
  [userId]="profileDetails.email">
</app-edit-advisor>

<upload-advisors (close)="closeWindow()" *ngIf="uploadAdvisor">
</upload-advisors>

<!-- Kyc Revert -->
<app-kyc-revert (close)="kycRevert = false" *ngIf="kycRevert" [profileId]="profileDetails.id">
</app-kyc-revert>

<!-- Expandable view -->
<app-user-expanded-tabs *ngIf="expandTabs" [showProfileAccount]="true" (hideExpandedView)="closeExpandedWindow()"
  [tenantCurrency]="tenantCurrency" [profileDetails]="profileDetails" [networkName]="networkName" [providerAutosaves]="providerAutosaves">
</app-user-expanded-tabs>



<slideover-user-dash (closeWindow)="closeWindow()" [zIndex]="zIndex">
  <div class="xn-fadein-up">
    <div class="user-profile-content">
      <mat-grid-list cols="4" gutterSize="20" rowHeight="">
        <!-- Profile card -->
        <mat-grid-tile colspan="1" class="details-panel">
          <div class="panel-a">
            <div class="prof-card grid-tile-container-prof">
              <div class="">
                <ng-container *ngIf="isActive">
                  <div class="profile-btn" *appSecAccess="['ROLE_COMPLIANCE', 'ROLE_SUPER_ADMIN']">
                    <div class="" role="group">
                      <div (click)="isOpenOption()" aria-expanded="false" class="act-btn" color="primary"
                        [matMenuTriggerFor]="actions">
                        <span>Actions</span> <img src="assets/icons/down-chevron.svg" class="dropdown-chevron">
                      </div>
                      <mat-menu #actions="matMenu">
                        <ng-container>
                        <button mat-menu-item (click)="activate(profileDetails?.id)"
                          *ngIf="!isProspect">Activate</button>
                        </ng-container>
                        <ng-container>
                          <button mat-menu-item data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="deactivate(profileDetails?.id)"
                                  *ngIf="!isProspect">Deactivate</button>
                        </ng-container>


                        <ng-container>
                          <button mat-menu-item  (click)="openRevertKyc(profileDetails?.id)"
                                  *ngIf="!isProspect">Reject</button>
                        </ng-container>

                        <ng-container
                          *appSecAccess="['ROLE_CLOSE_AUTOSAVE', 'ROLE_SUPER_ADMIN']"
                        >
                          <button mat-menu-item data-bs-toggle="modal"
                                  data-bs-target="#exampleModal" (click)="setReason(profileDetails?.id)"
                                  *ngIf="!isProspect">Cancel AutoSave</button>
                        </ng-container>

                      <!-- Uncomment once work is done on deactivate profile -->
                       <button mat-menu-item  [matMenuTriggerFor]="edit">Edit</button>
                        <!-- <ng-container *ngIf="profileDetails?.ussdPinActionRequest === true">
                          <ng-container>
                            <button mat-menu-item (click)="openResetPINView()">Reset PIN</button>
                          </ng-container>
                        </ng-container>  -->
                      </mat-menu>
                      <mat-menu #edit="matMenu">
                        <button mat-menu-item (click)="openEditIdView()" *ngIf="!isProspect">Identification</button>
                        <button mat-menu-item (click)="openPersonalEditWindow()">Personal Info</button>
                        <button mat-menu-item (click)="openProfileAddressEditWindow()"
                          *ngIf="!isProspect">Address</button>
                      </mat-menu>
                    </div>

                  </div>

                </ng-container>
                <mat-grid-list cols="2" gutterSize="2" rowHeight="162px">
                  <mat-grid-tile colspan="1">
                    <div>
                      <div class="prof-img-container">
                        <img (click)="showFilePreview('passport', 'Image')"
                          [src]="!passportUrl ? '../../../assets/images/orion_avatar.png' : passportUrl" alt="Selfie"
                          class="selfie-image" mat-card-image />
                        <pdf-viewer (click)="showFilePreview('passport', 'PDF')" *ngIf="passportFileType == 'PDF'"
                          [autoresize]="true" [external-link-target]="'blank'" [original-size]="false"
                          [render-text]="true" [show-all]="true" [show-borders]="false" [src]="passportUrl"
                          style="width: 100%; height: 200px;"></pdf-viewer>
                      </div>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                    <div class="right-section">
                      <div class="prof-name">
                        {{profileDetails?.firstName ? (profileDetails?.firstName + ' ' + (profileDetails?.middleName ?
                        profileDetails?.middleName : '') + ' ' +
                        profileDetails?.lastName) : '...' | titlecase }}</div>
                      <div><span>Joined: </span> <span class="content-txt">{{ profileDetails?.dateCreated | date
                          }}</span></div>
                      <div><span>Source: </span> <span class="content-txt">{{profileDetails?.source}}</span></div>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
              <div class="border-line-bottom-card-prof" *appSecAccess="['ROLE_ADVISOR_SUPERVISOR', 'ROLE_ADVISOR' ]">
              </div>
              <div class="lower-view" *appSecAccess="['ROLE_ADVISOR_SUPERVISOR', 'ROLE_ADVISOR' ]">
                <div class="prof-details">
                  <span>Total AUM</span>
                  <div class="content-txt">
                    <span class="currency-code">{{tenantCurrency}}</span> <span
                      class="content-txt-aum">{{profileDetails?.profileTotalAUM ?
                      (profileDetails?.profileTotalAUM | number: "1.0-0") : 0}}</span>
                  </div>
                </div>
                <div class="prof-details" *ngIf="tenantCurrency == 'UGX'">
                  <span>Band</span>
                  <span class="content-txt">
                    <span *ngIf="profileDetails?.band" class="mo-label {{
                          profileDetails?.band | lowercase
                   }} band-txt">{{profileDetails.band}}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="lower-view" *appSecAccess="['ROLE_PIN_RESET']">
              <ng-container *ngIf="profileDetails?.ussdPinActionRequest === true">
                <br>
                <div class="u-justify">
                  <div></div>
                  <button class="btn btn-primary" type="button" (click)="openResetPINView()">Reset PIN</button>
                </div>
              </ng-container>
            </div>

            <div class="grid-tile-container">
              <div class="kyc-card ">
                <mat-grid-list cols="2" gutterSize="10" rowHeight="80px">
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Network names</span>
                      <span *ngIf="networkName?.firstName && networkName?.lastName" class="content-txt">{{
                        networkName?.firstName | titlecase }} {{networkName?.lastName | titlecase}}</span>
                      <span *ngIf="isCheckingNames" class="xn-grey">Loading...</span>
                      <span *ngIf="isCheckingNames == false && networkName?.firstName == null"
                        class="content-txt">---</span>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Profile Phone Number</span>
                      <span class="content-txt">
                        {{profileDetails?.profilePhoneNumber ? profileDetails?.profilePhoneNumber : ' --- '}}
                      </span>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="1" gutterSize="10" rowHeight="80px">
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Email</span>
                      <span class="content-txt">
                        {{profileDetails?.email ? profileDetails?.email : ' --- '}}
                      </span>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="3" gutterSize="10" rowHeight="80px">
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>KYC status</span>
                      <span class="xn-left-16 x-text">
                        <span *ngIf="profileDetails?.kycStatus" class="status-label {{
                                profileDetails?.kycStatus | lowercase
                         }} xn-font-size">{{profileDetails?.kycStatus | lowercase}}</span>
                      </span>
                    </div>

                  </mat-grid-tile>
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Verified by</span>
                      <span class="content-txt">
                        {{profileDetails?.verifiedBy ? profileDetails?.verifiedBy : ' --- '}}
                      </span>
                    </div>

                  </mat-grid-tile>
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Verified on</span>
                      <span class="content-txt">
                        {{profileDetails?.verifiedOn ? (profileDetails?.verifiedOn | date) : ' --- '}}
                      </span>
                    </div>
                  </mat-grid-tile>

                </mat-grid-list>

                <div class="border-line-bottom"></div>
                <mat-grid-list cols="2" gutterSize="10" rowHeight="80px">
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Funded</span>
                      <span class="content-txt">
                        <span class="status-label {{ profileDetails?.fundedStatus | lowercase }}">
                          {{ profileDetails?.fundedStatus ? (setFundedStatus(profileDetails?.fundedStatus) | lowercase)
                          : '' }}
                        </span>
                      </span>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Dashboard access</span>
                      <span class="content-txt">
                        <span *ngIf="profileDetails?.status === true" class="status-label active">true</span>
                        <span *ngIf="profileDetails?.status === false" class="status-label disabled">false</span>
                      </span>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="2" gutterSize="10" rowHeight="80px">
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Can login</span>
                      <span class="content-txt">
                        <span *ngIf="profileDetails?.userStatus === true" class="status-label active">true</span>
                        <span *ngIf="profileDetails?.userStatus === false" class="status-label disabled">false</span>
                      </span>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile>
                    <!-- <div class="prof-details-top">
                      <span>Last login</span>
                      <span class="content-txt">
                        {{profileDetails?.lastLogin ? profileDetails?.lastLogin : ' --- '}}
                      </span>
                    </div> -->
                  </mat-grid-tile>
                </mat-grid-list>
                <div class="border-line-bottom"></div>
                <mat-grid-list cols="2" gutterSize="10" rowHeight="80px">
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>KYC revert</span>
                      <span class="content-txt">
                        <span *ngIf="profileDetails?.kycReverted === true" class="status-label active">true</span>
                        <span *ngIf="profileDetails?.kycReverted === false" class="status-label disabled">false</span>

                      </span>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile>
                    <div class="prof-details-top">
                      <span>Discovery details</span>
                      <span>
                        <span class="content-txt">{{ profileDetails?.discovery == null ? 'N/A' :
                          profileDetails?.discovery }}</span>
                      </span>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="2" gutterSize="10" rowHeight="80px">
                  <mat-grid-tile *ngIf="!isProspect">
                    <div class="prof-details-top" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                      <span>Email Subscription</span>
                      <mat-slide-toggle (change)="onEmailSubscriptionChange($event)"
                        (click)="onEmailSubscriptionClick($event)" (keyup.space)="onEmailSubscriptionChange($event)"
                        [(ngModel)]="emailSubscriptionCheck">
                      </mat-slide-toggle>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
            </div>
          </div>
        </mat-grid-tile>
        <!-- Kyc Card -->
        <mat-grid-tile colspan="3" class="tab-panel">
          <!-- Tab Data -->
          <div *ngIf="!isProspect" class="left-panel">
            <div *ngIf="!isLoading" class="expand-tab">
              <div></div>
              <mat-icon [inline]="true" (click)="expandProfileAccounts(profileDetails)"
                class="expand-icon">fullscreen</mat-icon>
            </div>
            <mat-tab-group animationDuration="0ms" class="xn-mat-tab-label-container mat-tab-header">
              <mat-tab class="mat-label-details" aria-label="profile" label="KYC">
                <div class="user-profile-view">

                  <div class="x-container ">
                    <div class="column card-details">
                      <div class="">
                        <div class="u-justify is-table-header">
                          <h2 class="page-subtitle-dash xn-22">Identification <span class="xn-left-16 x-text">
                              <span
                                class="status-label {{ setStatus(profileDetails?.itemKycStatus?.idStatus) | lowercase }} xn-font-size">
                                {{ setStatus(profileDetails?.itemKycStatus?.idStatus) | lowercase }}
                              </span></span></h2>
                        </div>

                        <table class="xn-table mat-elevation-z1 is-summary xn-mtop mat-radius xn-table-height367">
                          <tbody class="table-txt">
                            <tr>
                              <td class="u-text">ID Type</td>
                              <td>
                                <span *ngIf="profileDetails?.identificationType">{{ profileDetails?.identificationType
                                  }}</span>
                                <span *ngIf="profileDetails?.identificationType == null"></span>
                              </td>
                            </tr>
                            <tr>
                              <td class="u-text">ID Number</td>
                              <td *ngIf="profileDetails?.identificationNumber">{{ profileDetails?.identificationNumber
                                }}
                              </td>
                              <td *ngIf="profileDetails?.identificationNumber == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">ID File</td>
                              <td>
                                <p>
                                  <button (click)="showFilePreview('id', 'Image')" *ngIf="idFileType == 'Image'"
                                    class="image-button id-img-container">
                                    <img [src]="idUrl" alt="Id File" />
                                  </button>
                                  <pdf-viewer (click)="showFilePreview('id', 'PDF')" *ngIf="idFileType == 'PDF'"
                                    [autoresize]="true" [external-link-target]="'blank'" [original-size]="false"
                                    [render-text]="true" [show-all]="true" [show-borders]="false" [src]="idUrl"
                                    style="width: 100%; height: 200px;"></pdf-viewer>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="column card-details">
                      <div class="">
                        <div class="u-justify is-table-header">
                          <h2 class="page-subtitle-dash xn-22">
                            <span>Personal Information</span>
                            <span class="xn-left-16 x-text"><span
                                class="status-label {{ setStatus(profileDetails?.itemKycStatus?.bioStatus) | lowercase }} xn-font-size">
                                {{ setStatus(profileDetails?.itemKycStatus?.bioStatus) | lowercase }}
                              </span></span>
                          </h2>

                          <!--                <div *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">-->
                          <!--                  <button mat-flat-button-->
                          <!--                          color="primary"-->
                          <!--                          (click)="openPersonalEditWindow()"-->
                          <!--                          aria-label="xeno menu dropdown">-->
                          <!--                    Edit-->
                          <!--                  </button>-->
                          <!--                </div>-->

                        </div>

                        <table class="xn-table mat-elevation-z1 is-summary xn-mtop mat-radius xn-table-height367">
                          <tbody class="table-txt">
                            <tr>
                              <td class="u-text">Gender</td>
                              <td *ngIf="profileDetails?.gender">{{ profileDetails?.gender }}</td>
                              <td *ngIf="profileDetails?.gender == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Email</td>
                              <td *ngIf="profileDetails?.email">{{ profileDetails?.email }}</td>
                              <td *ngIf="profileDetails?.email == null"></td>
                            </tr>
                            <tr *ngIf="showKenyanPIN">
                              <td class="u-text">KRA PIN</td>
                              <td *ngIf="profileDetails?.tinNumber">{{ profileDetails?.tinNumber }}</td>
                              <td *ngIf="profileDetails?.tinNumber == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Nationality</td>
                              <td *ngIf="profileDetails?.nationality">{{ profileDetails?.nationality }}</td>
                              <td *ngIf="profileDetails?.nationality == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Country Code</td>
                              <td *ngIf="profileDetails?.countryCode">{{ profileDetails?.countryCode }}</td>
                              <td *ngIf="profileDetails?.countryCode == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Phone Number</td>
                              <td *ngIf="profileDetails?.phoneNumber">{{ profileDetails?.phoneNumber }}</td>
                              <td *ngIf="profileDetails?.phoneNumber == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Profile Phone Number</td>
                              <td *ngIf="profileDetails?.profilePhoneNumber">{{ profileDetails?.profilePhoneNumber }}
                              </td>
                              <td *ngIf="profileDetails?.profilePhoneNumber == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Date of Birth</td>
                              <td *ngIf="profileDetails?.dateOfBirth">{{ profileDetails?.dateOfBirth | date }}</td>
                              <td *ngIf="profileDetails?.dateOfBirth == null"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="x-container">
                    <div class="column card-details">
                      <div class="">
                        <div class="u-justify is-table-header">
                          <h2 class="page-subtitle-dash xn-22">Address <span class="xn-left-16">
                              <span
                                class="status-label {{ setStatus(profileDetails?.itemKycStatus?.addressStatus) | lowercase }} xn-font-size x-text">
                                {{ setStatus(profileDetails?.itemKycStatus?.addressStatus) | lowercase }}
                              </span></span></h2>

                          <!--                <div *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">-->
                          <!--                  <button mat-flat-button-->
                          <!--                          color="primary"-->
                          <!--                          (click)="openProfileAddressEditWindow()"-->
                          <!--                          aria-label="xeno menu dropdown">-->
                          <!--                    Edit-->
                          <!--                  </button>-->
                          <!--                </div>-->
                        </div>

                        <table class="xn-table mat-elevation-z1 is-summary xn-top-9n mat-radius">
                          <tbody>
                            <tr class="table-txt">
                              <td class="u-text">Country</td>
                              <td *ngIf="profileDetails?.address?.country">{{ profileDetails?.address?.country }}</td>
                              <td *ngIf="profileDetails?.address?.country == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Region / Province / State</td>
                              <td *ngIf="profileDetails?.address?.region">{{ profileDetails?.address?.region }}</td>
                              <td *ngIf="profileDetails?.address?.region == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">City / District</td>
                              <td *ngIf="profileDetails?.address?.district">{{ profileDetails?.address?.district }}</td>
                              <td *ngIf="profileDetails?.address?.district == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Street / Road</td>
                              <td *ngIf="profileDetails?.address?.street">{{ profileDetails?.address?.street }}</td>
                              <td *ngIf="profileDetails?.address?.street == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">Apartment / Suite / Plot Number</td>
                              <td *ngIf="profileDetails?.address?.houseNumber">{{ profileDetails?.address?.houseNumber
                                }}
                              </td>
                              <td *ngIf="profileDetails?.address?.houseNumber == null"></td>
                            </tr>
                            <tr>
                              <td class="u-text">ZIP / Post</td>
                              <td *ngIf="profileDetails?.address?.boxNumber">{{ profileDetails?.address?.boxNumber }}
                              </td>
                              <td *ngIf="profileDetails?.address?.boxNumber == null"></td>
                            </tr>
                          </tbody>
                        </table>


                      </div>
                    </div>
                    <div class="column card-details">
                      <div class="u-justify is-table-header">
                        <h2 class="page-subtitle-dash">
                          <span>Income source</span>
                          <span class="xn-left-16 x-text"><span
                              class="status-label {{ setStatus(profileDetails?.itemKycStatus?.incomeStatus) | lowercase }} xn-font-size">
                              {{ setStatus(profileDetails?.itemKycStatus?.incomeStatus) | lowercase }}
                            </span></span>
                        </h2>
                      </div>
                      <!-- income sources -->

                      <table class="xn-table mat-elevation-z1 no-table-hover xn-top-9n">
                        <thead class="table-header">
                          <tr>
                            <th class="u-text">Type</th>
                            <th class="u-text">Name</th>
                            <th class="u-text">Amount ({{tenantCurrency}})</th>
                            <th class="u-text">Monthly Investment ({{tenantCurrency}})</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container *ngIf="profileDetails?.incomeSources">
                            <tr class="table-txt" *ngFor="let income of profileDetails?.incomeSources">
                              <td>
                                {{ income?.source }}
                              </td>
                              <td>{{ income?.employer }}</td>
                              <td>{{income?.monthlyIncome == 0 ? '' : tenantCurrency}} {{ income?.monthlyIncome == 0 ? income?.monthlyIncomeRange : income?.monthlyIncome }}</td>
                              <td>{{tenantCurrency}} {{ income?.expectedMonthlyIncome }}</td>
                            </tr>
                          </ng-container>
                          <tr
                            *ngIf="profileDetails?.incomeSources?.length == 0 || profileDetails?.incomeSources == null">
                            <td colspan="4">
                              <div class="xn-center-content mt-3">
                                <div>
                                  <div align="center">
                                    <img alt="warning" src="./assets/icons/file-warning.svg" />
                                  </div>

                                  <p>
                                    No income source found
                                  </p>
                                </div>



                              </div>

                            </td>
                          </tr>

                        </tbody>
                      </table>

                    </div>
                  </div>

                </div>
              </mat-tab>
              <mat-tab aria-label="accounts" label="Accounts">
                <mat-tab-group animationDuration="0ms" class="xn-mat-tab-label-container mat-tab-header">
                  <mat-tab class="mat-label-details" aria-label="activeAccounts" label="Active Accounts">
                    <div class="user-profile-view-accounts">
                      <div *ngFor="let account of activeAccounts" class="u-ptb-16 u-fadeInY">
                        <div *ngIf="account.hide === false" class="account-wrap table-details">
                          <div class="u-justify">
                            <div class="account-header">
                              <h3>
                                <span class="page-subtitle-accounts">{{ account.accountName | titlecase }}</span>
                              </h3>
                              <div *ngIf="account.accountType === 'POOLED' ||
                             account?.accountType === 'SPONSOR' || account.accountType === 'LINKED'">
                                <p><span style="font-weight: bold">Institution
                                    Name:</span>{{ account?.institutionName}}&nbsp;&nbsp;<span
                                    style="font-weight: bold">Sponsor Code:</span>{{account?.sponsorCode}}&nbsp;&nbsp;
                                  <span style="font-weight: bold">Type:</span>{{ account?.accountType |
                                  titlecase}}&nbsp;&nbsp;
                                  <span style="font-weight: bold">No.</span>{{ account?.accountNumber}}&nbsp;&nbsp;
                                </p>
                              </div>
                              <p *ngIf="account?.accountType === 'PERSONAL' ||
                       account?.accountType === 'JOINT'" class="pointer">
                                {{ account?.accountType | titlecase }}, {{ account?.accountNumber }}
                                <!-- <span *ngIf="account.hide === false"> Status: <span class="status-label active">
                                                ACTIVE</span>
                                            </span>
                                            <span *ngIf="account.hide === true">Status: <span class="status-label inactive">
                                                INACTIVE</span>
                                            </span> -->
                                <span *ngIf="account.accountType === 'JOINT'">Members:<span>[{{
                                    account?.jointAccountMembers
                                    }}]</span></span>
                              </p>
                            </div>

                            <div *appSecAccess="['ROLE_COMPLIANCE', 'ROLE_SUPER_ADMIN']">
                              <div class="float-right b-actions">
                                <div aria-label="Button group with nested dropdown" class="btn-group" role="group">
                                  <div class="btn-group" role="group">
                                    <button aria-expanded="false" class="btn btn-primary dropdown-toggle"
                                      data-bs-toggle="dropdown" type="button">
                                      Actions
                                    </button>
                                    <ul class="dropdown-menu">
                                      <ng-container *ngIf="account?.hide == true">
                                        <li><a (click)="activateProfileAccount(account.accountId)"
                                            class="dropdown-item pointer">Enable Account</a></li>
                                      </ng-container>
                                      <ng-container *ngIf="account?.hide == false">
                                        <li><a (click)="deactivateProfileAccount(account.accountId)"
                                            class="dropdown-item pointer">Disable Account</a></li>
                                      </ng-container>
                                      <ng-container *ngIf="account?.canManageBankAccounts == true">
                                        <li><a (click)="canManageBankAccounts(account.accountId, false)"
                                            class="dropdown-item pointer">Disable Manage Bank Accounts</a></li>
                                      </ng-container>
                                      <ng-container *ngIf="account?.canManageBankAccounts == false">
                                        <li><a (click)="canManageBankAccounts(account.accountId, true)"
                                            class="dropdown-item pointer">Enable Manage Bank Accounts</a></li>
                                      </ng-container>
                                      <li *ngIf="account?.accountType ==='JOINT'">
                                        <a class="dropdown-item pointer"
                                          (click)="removePartner(profileDetails?.id, account?.accountId)">Remove
                                          Partner</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <table class="xn-table is-fixed">
                            <thead class="table-header">
                              <tr>
                                <th>Goal</th>
                                <th>Number</th>
                                <th>Net Invested</th>
                                <th>Current Value</th>
                                <!--                <th>Last Deposit Date</th>-->
                                <th>Status</th>
                                <th>Withdraw Lock</th>
                                <!-- <th *ngIf="!tenantId">Active AutoSaves</th> -->
                                <th *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr class="table-txt" *ngFor="let accountGoal of account.accountGoals">
                                <td>
                                  <a class="user-name-label" (click)="showGoalDetails(accountGoal.id, account.accountId, account.accountType)">{{
                                    accountGoal.goalTitle | titlecase }}</a>
                                </td>
                                <td>{{ accountGoal.goalNumber }}</td>
                                <td><span class="currency-code">{{tenantCurrency}}</span> {{ accountGoal.netInvested |
                                  number: "1.0-0"
                                  }}
                                </td>
                                <td><span class="currency-code">{{tenantCurrency}}</span> {{ accountGoal.currentValue |
                                  number: "1.0-0"
                                  }}</td>
                                <!--                <td><span *ngIf="accountGoal.lastDepositDate">{{accountGoal.lastDepositDate | date}}</span>-->
                                <!--                <span *ngIf="accountGoal.lastDepositDate == null"></span>-->
                                <!--                </td>-->
                                <td>
                                  <span *ngIf="accountGoal.disabled === true"
                                    class="status-label disabled">Disabled</span>
                                  <span *ngIf="accountGoal.disabled === false"
                                    class="status-label active">Enabled</span>
                                </td>
                                <td>
                                  <span *ngIf="accountGoal.isWithdrawalLocked === true"
                                    class="status-label active">True</span>
                                  <span *ngIf="accountGoal.isWithdrawalLocked === false"
                                    class="status-label disabled">False</span>
                                </td>

                                <!-- <td *ngIf="!tenantId">
                                  {{getTotalGoalAutoSaves(accountGoal.id)}}
                                </td> -->
                                <td *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                                  <ng-container>
                                    <button [matMenuTriggerFor]="menu" mat-button>
                                      <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                      <button (click)="enableAccountGoal(accountGoal.id)" mat-menu-item>Enable</button>
                                      <button (click)="disableAccountGoal(accountGoal.id)"
                                        mat-menu-item>Disable</button>
                                      <button (click)="setWithdrawLockForGoal(accountGoal.id)"
                                        *ngIf="accountGoal.isWithdrawalLocked === false" mat-menu-item>Set Withdraw Lock
                                      </button>
                                      <button (click)="removeWithdrawLockForGoal(accountGoal.id)"
                                        *ngIf="accountGoal.isWithdrawalLocked === true" mat-menu-item>Remove Withdraw
                                        Lock
                                      </button>
                                    </mat-menu>
                                  </ng-container>

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab aria-label="inactiveAccounts" label="Inactive Accounts">
                    <div class="user-profile-view-accounts">

                      <div *ngFor="let account of inActiveAccounts" class="u-ptb-16 u-fadeInY">
                        <div *ngIf="account.hide === true" class="account-wrap table-details">
                          <div class="u-justify">
                            <div class="account-header">
                              <h3>
                                <span class="page-subtitle-accounts">{{ account.accountName | titlecase }}</span>
                              </h3>
                              <div *ngIf="account.accountType === 'POOLED' ||
                             account.accountType === 'SPONSOR'  || account.accountType === 'LINKED'">
                                <p (click)="goToAccount()" class="pointer"><span style="font-weight: bold">Institution
                                    Name:</span> {{account.institutionName}}<span style="font-weight: bold">Sponsor
                                    Code:</span>
                                  &nbsp;&nbsp;&nbsp; {{account.sponsorCode}}
                                  <span style="font-weight: bold">Type:</span> &nbsp;&nbsp;&nbsp; {{ account.accountType
                                  |
                                  titlecase }}

                                  <span style="font-weight: bold">No.</span> {{ account.accountNumber }}
                                </p>
                              </div>
                              <p *ngIf="account.accountType === 'PERSONAL' ||
                             account.accountType === 'JOINT'" class="pointer">
                                {{ account.accountType | titlecase }}, {{ account.accountNumber }}
                              </p>
                              <!-- <p *ngIf="account.hide === false" class="pointer">
                                            Status: <span class="status-label active"> ACTIVE</span>
                                          </p>
                                          <p *ngIf="account.hide === true" class="pointer">
                                            Status: <span class="status-label inactive"> INACTIVE</span>
                                          </p> -->
                            </div>
                            <div *ngIf="account.hide == true" class="b-actions">
                              <div *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                                <button (click)="activateProfileAccount(account.accountId)" color="primary" mat-button
                                  mat-flat-button>
                                  Enable
                                </button>
                              </div>
                            </div>
                            <div *ngIf="account.hide == false" class="b-actions">
                              <div *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                                <button (click)="deactivateProfileAccount(account.accountId)" color="primary" mat-button
                                  mat-flat-button>
                                  Disable
                                </button>
                              </div>
                            </div>

                          </div>
                          <table class="xn-table is-fixed">
                            <thead class="table-header">
                              <tr>
                                <th>Goal</th>
                                <th>Number</th>
                                <th>Net Invested</th>
                                <th>Current Value</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr *ngFor="let accountGoal of account.accountGoals"
                                (click)="showGoalDetails(accountGoal.id, account.accountId, account.accountType)">
                                <td>
                                  {{ accountGoal.goalTitle | titlecase }}
                                </td>
                                <td>{{ accountGoal.goalNumber }}</td>
                                <td>{{tenantCurrency}} {{ accountGoal.netInvested | number: "1.0-0" }}</td>
                                <td>{{tenantCurrency}} {{ accountGoal.currentValue | number: "1.0-0" }}</td>
                                <td>
                                  <span class="status-label disabled">Disabled</span>
                                </td>
                              </tr>


                            </tbody>
                          </table>
                        </div>
                      </div>
                      <ng-container *ngIf="inActiveAccounts.length == 0">
                        <div class="xn-center-content">
                          <div>
                            <div align="center">
                              <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />

                            </div>
                            <div>
                              <p>
                                No inactive accounts available
                              </p>
                            </div>

                          </div>


                        </div>
                      </ng-container>

                    </div>
                  </mat-tab>
                </mat-tab-group>

              </mat-tab>
              <ng-container *ngIf="!tenantId">

              <mat-tab aria-label="providerAutosaves" label="AutoSaves">
                <ng-container *ngIf="fetchingAutosaves">
                  <div class="user-profile-view">

                  <table class="xn-table no-table-hover">
                    <tr>
                      <td colspan="11">
                        <div class="xn-center-content">
                          <div>
                            <div>
                              <p>
                               Loading AutoSaves...
                              </p>
                            </div>

                          </div>


                        </div>

                      </td>
                    </tr>
                  </table>
                  </div>
                </ng-container>

                <ng-container *ngIf="!hasAutoSaves && !fetchingAutosaves">
                  <div class="user-profile-view">

                  <table class="xn-table no-table-hover table-details">
                    <thead class="table-header">
                      <tr>
                        <th>Created</th>
                        <th>Autosave Amount</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Start Date</th>
                        <th>Status</th>
                        <th>Clearing Day</th>
                        <th>Total Deposited</th>
                        <th>Total Failed</th>
                        <th>Executions</th>
                        <th>Successful</th>
                        <th>Failed</th>
                      </tr>
                    </thead>
                    <tbody >
                  <tr>
                    <td colspan="11">
                      <div class="xn-center-content">
                        <div>
                          <div align="center">
                            <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />

                          </div>
                          <div>
                            <p>
                              No Active AutoSaves
                            </p>
                          </div>

                        </div>


                      </div>

                    </td>
                  </tr>
                  </tbody>
                </table>
                  </div>
                </ng-container>
                <ng-container  *ngIf="hasAutoSaves && !fetchingAutosaves">


                <mat-tab-group animationDuration="0ms" class="xn-mat-tab-label-container mat-tab-header">

                  <ng-container *ngFor="let autoSave of providerAutosaves" >
                    <mat-tab class="mat-label-details" aria-label="{{autoSave.paymentProvider.name}}" label={{autoSave.paymentProvider.name}}>
                      <div class="user-profile-view">
                        <ng-container *ngFor="let providerAutosaves of autoSave?.autoSaveSummary">
                        <!-- <div>Active AutoSaves{{providerAutosaves.status == 'ACTIVE'}}</div> -->
                        </ng-container>
                        <table class="xn-table no-table-hover table-details">
                          <thead class="table-header">
                            <tr>
                              <th>Created</th>
                              <th>Autosave Amount</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Start Date</th>
                              <th>Status</th>
                              <th>Clearing Day</th>
                              <th>Total Deposited</th>
                              <th>Total Failed</th>
                              <th>Executions</th>
                              <th>Successful</th>
                              <th>Failed</th>
                            </tr>
                          </thead>
                          <tr class="hidden">{{activeAutoSaves(autoSave?.autoSaveSummary)}}</tr>

                              <ng-container *ngIf="activeAutoSaveCount == 0" >
                                <tbody >
                                <tr>
                                  <td colspan="11">
                                    <div class="xn-center-content">
                                      <div>
                                        <div align="center">
                                          <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />

                                        </div>
                                        <div>
                                          <p>
                                            No Active AutoSaves
                                          </p>
                                        </div>

                                      </div>


                                    </div>

                                  </td>
                                </tr>
                              </tbody>
                              </ng-container>
                          <ng-container *ngFor="let providerAutosaves of autoSave?.autoSaveSummary ">
                            <tbody >
                              <ng-container *ngIf="providerAutosaves.status == 'ACTIVE'">
                                <tr class="table-txt">
                                  <td>
                                    <p>
                                      <span class="u-block">{{ providerAutosaves?.dateCreated | date}}</span>
                                    </p>
                                  </td>
                                  <td>
                                    <p class="text-auto">
                                      <span class="u-block content-txt"><span class="currency-code">{{tenantCurrency}}  </span>{{ providerAutosaves?.amount | number: "1.0-0" }}</span>
                                      <span class="u-block u-txt-small">{{providerAutosaves?.frequency}}</span>
                                    </p>
                                  </td>
                                  <td>
                                    <ng-container *ngIf="autoSave.paymentProvider.type == 'BANK'">
                                      <div class="avatar-txt">
                                        <img
                                        [src]="autoSave.paymentProvider.avatarUrl" alt="avatar"
                                        class="avatar-icon" mat-card-image />
                                      <p>
                                        <span class="u-block">{{providerAutosaves?.bankAccount.bankName }}</span>
                                        <span class="u-block u-txt-small">{{
                                          providerAutosaves?.bankAccount.accountName
                                          }}</span>
                                        <span class="u-block u-txt-small">{{
                                          providerAutosaves?.bankAccount.accountNumber
                                          }}</span>
                                      </p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="autoSave.paymentProvider.type == 'MOBILE_NETWORK'">
                                      <div class="avatar-txt">
                                      <img
                                      [src]="autoSave.paymentProvider.avatarUrl" alt="avatar"
                                      class="avatar-icon" mat-card-image />
                                                  <p>
                                                    <span class="u-block">{{autoSave.paymentProvider.name }}</span>
                                                    <span *ngIf="networkName?.firstName && networkName?.lastName" class="u-block u-txt-small">{{
                                                      networkName?.firstName | titlecase }} {{networkName?.lastName | titlecase}}</span>
                                                    <span *ngIf="isCheckingNames" class="xn-grey">Loading...</span>
                                                    <span *ngIf="isCheckingNames == false && networkName?.firstName == null"
                                                      class="u-block u-txt-small">---</span>
                                                    <span class="u-block u-txt-small">{{providerAutosaves?.phoneNumber.countryPrefix}}{{providerAutosaves?.phoneNumber.localNumber}}</span>
                                                  </p>

                                      </div>

                                    </ng-container>

                                  </td>
                                  <td>
                                    <div class="avatar-txt">
                                      <img
                                      [src]="'../../../assets/images/goals/goal-'+providerAutosaves?.goal.goalRefNumber+'.jpg'" alt="avatar"
                                      class="avatar-icon" mat-card-image />
                                    <p>
                                      <span class="u-block">{{providerAutosaves?.goal.goalTitle | titlecase}} Goal</span>
                                      <span class="u-block u-txt-small">{{
                                        providerAutosaves?.goal.goalNumber
                                        }}</span>
                                    </p>
                                      </div>
                                  </td>
                                  <td>
                                    <p>
                                      <span class="u-block">{{ providerAutosaves?.startDate | date}}</span>
                                      <span class="u-block u-txt-small">Until i cancel</span>
                                    </p>
                                  </td>
                                  <td>
                                    <span class="status-label {{
                                      providerAutosaves?.status == 'ACTIVE' ? 'active' :
                                      providerAutosaves?.status == 'FAILED' ? 'failed' :
                                       providerAutosaves?.status == 'CANCELED' ? 'cancelled' :
                                       providerAutosaves?.status == 'PENDING' ? 'pending' :
                                         'not-set'}}">{{providerAutosaves?.status}}</span>
                                  </td>
                                  <td>{{ providerAutosaves?.clearingDay }}</td>
                                  <td>
                                    <p>
                                      <span class="u-block deposits"><span class="currency-code">{{tenantCurrency}}  </span>{{ providerAutosaves?.totalSuccessfulDeposits | number: "1.0-0" }}</span>
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <span class="u-block failed-transactions"><span class="currency-code">{{tenantCurrency}}  </span>{{ providerAutosaves?.totalFailedDeposits | number: "1.0-0" }}</span>
                                    </p>
                                  </td>
                                  <td>{{ providerAutosaves?.totalTransactions }}</td>
                                  <td>{{ providerAutosaves?.countSuccessfulDeposits }}</td>
                                  <td>
                                    {{ providerAutosaves?.countFailedDeposits }}
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </ng-container>
                        </table>

                      </div>
                        </mat-tab>
                  </ng-container>

                  </mat-tab-group>

                </ng-container>

              </mat-tab>

              </ng-container>
              <mat-tab aria-label="bankDetails" label="Bank Accounts">
                <div class="user-profile-view">
                  <table class="xn-table no-table-hover table-details">
                    <thead class="table-header">
                      <tr>
                        <th>XENO Account</th>
                        <th>Bank Name</th>
                        <th>Bank Account Name</th>
                        <th>Bank Account Number</th>
                        <th>SWIFT/IBAN Code</th>
                        <th>Branch</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <ng-container *ngFor="let account of profileDetails?.xenoAccounts">
                      <tbody>
                        <ng-container *ngFor="let bank of account?.bankAccounts">
                          <tr class="table-txt">
                            <td>
                              <p>
                                <span class="u-block">{{ account?.accountName }}</span>
                                <span class="u-block u-txt-small">{{
                                  account?.accountNumber
                                  }}</span>
                              </p>
                            </td>
                            <td>{{ bank?.bankName }}</td>
                            <td>{{ bank?.accountName }}</td>
                            <td>{{ bank?.accountNumber }}</td>
                            <td>{{ bank.bankCode }}
                              <span *ngIf="bank?.bankCode == null"></span>
                            </td>
                            <td>{{ bank?.branchName }}</td>
                            <td>
                              <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                                <button [matMenuTriggerFor]="menu" mat-button>
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                  <button (click)="openBankEditWindow(bank, account?.accountId)"
                                    mat-menu-item>Edit</button>
                                </mat-menu>
                              </ng-container>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </ng-container>
                    <ng-container *ngIf="isNoBankAccount">
                      <tr>
                        <td colspan="7">
                          <div class="xn-center-content">
                            <div>
                              <div align="center">
                                <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />

                              </div>
                              <div>
                                <p>
                                  No bank accounts available
                                </p>
                              </div>

                            </div>


                          </div>

                        </td>
                      </tr>
                    </ng-container>

                  </table>

                </div>
              </mat-tab>
              <mat-tab aria-label="beneficiaries" label="Beneficiaries">
                <!-- beneficiary -->
                <div class="user-profile-view">
                  <div class="page-section x-topn_66 table-details">
                    <table class="xn-table no-table-hover">
                      <thead class="table-header">
                        <tr>
                          <th>Name</th>
                          <th>Phone Number</th>
                          <th>Email</th>
                          <th>Relationship</th>
                          <th>Percentage</th>
                          <th *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">Actions</th>
                        </tr>
                      </thead>
                      <ng-container *ngFor="let account of profileDetails?.xenoAccounts">
                        <tbody>
                          <ng-container *ngFor="let beneficiary of account?.beneficiaries">
                            <tr class="table-txt">
                              <td>{{ beneficiary?.firstName }} {{ beneficiary?.lastName }}</td>
                              <td>{{ beneficiary?.telephone }}</td>
                              <td>{{ beneficiary?.email }}</td>
                              <td>{{ beneficiary?.relationShip }}</td>
                              <td>{{ beneficiary?.percentage }}%</td>
                              <td>
                                <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                                  <button [matMenuTriggerFor]="menu" mat-button>
                                    <mat-icon>more_vert</mat-icon>
                                  </button>
                                  <mat-menu #menu="matMenu">
                                    <button (click)="openAccountBeneficiaryEditWindow(beneficiary, account?.accountId)"
                                      mat-menu-item>
                                      Edit
                                    </button>
                                    <button (click)="deleteBeneficiary(beneficiary?.id)" mat-menu-item>Delete</button>
                                  </mat-menu>
                                </ng-container>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </ng-container>

                      <ng-container>
                        <tr *ngIf="isNoBeneficiary">
                          <td colspan="6">
                            <div class="xn-center-content">
                              <div>
                                <div align="center">
                                  <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />
                                </div>
                                <div>
                                  <p>
                                    No beneficiaries available
                                  </p>
                                </div>

                              </div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>

                    </table>
                  </div>

                </div>
              </mat-tab>

              <mat-tab aria-label="advisor" label="Advisor" *appSecAccess="['ROLE_ADVISOR_SUPERVISOR']">
                <div class="user-profile-view">
                  <div class=" xn-top10n table-details">
                    <div class="page-section xn-width xn-top10n">
                      <div class="u-justify is-table-header ">
                        <h2 class="page-subtitle-dash xn-b33">
                        </h2>
                        <div *appSecAccess="['ROLE_ADVISOR_SUPERVISOR', 'ROLE_SUPER_ADMIN']">
                          <br />
                          <div class="b-actions">
                            <button (click)="assignAdvisor(profileDetails.email)" class="btn btn-primary" type="button">
                              {{profileDetails?.advisors.length == 0 ? 'Assign Advisor' : 'Change Advisor'}}
                            </button>
                          </div>
                        </div>
                      </div>

                      <table class="xn-table  no-table-hover">
                        <thead class="table-header">
                          <tr>
                            <th class="u-text">Advisor Name</th>
                            <th class="u-text">Status</th>
                            <th class="u-text">Assigned Period</th>
                            <th class="u-text">Assigned By</th>
                            <th class="u-text">Assigned On</th>
                            <!-- <th class="u-text">Actions</th> -->
                          </tr>
                        </thead>

                        <tbody *ngFor="let advisor of profileDetails?.advisors">
                          <tr class="table-txt">
                            <td>
                              <p>
                                <span class="u-block">{{ advisor?.firstName | titlecase }}
                                  {{ advisor?.lastName | titlecase }}</span>
                              </p>
                            </td>
                            <td>
                              <span *ngIf="advisor?.isPreviousAdvisor === true"><span class="status-label inactive">
                                  Inactive</span>
                              </span>
                              <span *ngIf="advisor?.isPreviousAdvisor === false"><span class="status-label active">
                                  Active</span>
                              </span>
                            <td>{{ advisor?.assignedPeriod }}</td>
                            <td>
                              <ng-container *appSecAccess="['ROLE_ADVISOR_SUPERVISOR', 'ROLE_SUPER_ADMIN']">
                                <span>{{ advisor?.assignedBy }}</span>
                              </ng-container>
                            </td>
                            <td>
                              <ng-container *appSecAccess="['ROLE_ADVISOR_SUPERVISOR', 'ROLE_SUPER_ADMIN']">
                                <span>{{ advisor?.assignedOn | date }}</span>
                              </ng-container>
                            </td>
                            <!-- <td>
                              <ng-container *appSecAccess="['ROLE_ADVISOR_SUPERVISOR', 'ROLE_SUPER_ADMIN']">
                                <button *ngIf="advisor?.isPreviousAdvisor === false" [matMenuTriggerFor]="menu"
                                  mat-button>
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                  <button (click)="openAdvisorWindow(advisor, profileDetails?.email)"
                                    mat-menu-item>Edit</button>
                                </mat-menu>
                              </ng-container>
                            </td> -->
                          </tr>
                        </tbody>

                        <tbody *ngIf="isVisible">
                          <tr class="table-txt">
                            <td colspan="4">
                              <div class="xn-center-content">
                                <div>
                                  <div align="center">
                                    <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />

                                  </div>
                                  <div>
                                    <p class="center">
                                      No advisors available
                                    </p>
                                  </div>

                                </div>



                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>

                </div>

              </mat-tab>
              <mat-tab aria-label="notes" label="Notes" *appSecAccess="['ROLE_ADVISOR_SUPERVISOR', 'ROLE_ADVISOR' ]">
                <div class="user-profile-view">
                  <br>
                  <div class="note-header-section is-table-header ">
                    <div>
                      <div *ngIf="hasNoNotes">
                        <ng-container *ngIf="!toggleCardView">
                          <mat-icon class="toggle-icon" (click)="toggleTableView('showCards')">reorder</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="toggleCardView">
                          <mat-icon class="toggle-icon" (click)="toggleTableView('showTable')">view_module</mat-icon>
                        </ng-container>

                      </div>
                    </div>
                    <button (click)="addAdvisorNote(profileDetails.id)" class="btn btn-primary" type="button">
                      Add Note
                    </button>
                  </div>
                  <div class=" xn-top10n ">
                    <div class="page-section xn-width ">
                      <div *ngIf="hasNoNotes">

                        <div class="xn-top10n">
                          <ng-container *ngIf="!toggleCardView">

                            <mat-grid-list cols="3" gutterSize="20" rowHeight="190px">
                              <mat-grid-tile class="tile-shadow" *ngFor="let userNote of userNotes"
                                (click)="viewNote(userNote, profileDetails.id)" colspan="1">
                                <div class="notes-content">

                                  <h1 class="notes-subject">
                                    {{userNote?.subject}}
                                  </h1>
                                  <div class="notes-body">
                                    <p> {{userNote?.note}}</p>
                                  </div>
                                  <div class="border-line-bottom-notes"></div>
                                  <div class="notes-post">
                                    <span>
                                      {{userNote?.staff}}
                                    </span>
                                    <div class="notes-comments">
                                      <mat-icon class="note-icon"> comment</mat-icon>
                                      <span>{{userNote.totalReplies}}</span>
                                    </div>
                                    <span class="posted-on">
                                      {{userNote?.lastEditedBy != null ? 'Edited on: ' + (userNote?.lastEditedOn |
                                      date) : userNote?.postedOn }}
                                    </span>
                                  </div>

                                </div>

                              </mat-grid-tile>
                            </mat-grid-list>
                          </ng-container>
                          <ng-container *ngIf="toggleCardView">
                            <br />
                            <mat-grid-list class="table-tile" cols="9" gutterSize="20" rowHeight="50px"
                              *ngFor="let userNote of userNotes" (click)="viewNote(userNote, profileDetails.id)">
                              <div class="border-line-bottom-notes"></div>
                              <mat-grid-tile>
                                <span class="xn-text-span-header">{{userNote?.subject}}</span>
                              </mat-grid-tile>
                              <mat-grid-tile colspan="5">
                                <span class="xn-text-p">{{userNote?.note}}</span></mat-grid-tile>
                              <mat-grid-tile>
                                <span class="xn-text-span">
                                  {{userNote?.staff}}
                                </span>
                              </mat-grid-tile>
                              <mat-grid-tile>
                                <div class="notes-comments">
                                  <mat-icon class="note-icon"> comment</mat-icon> <span>{{userNote.totalReplies}}</span>
                                </div>
                              </mat-grid-tile>
                              <mat-grid-tile><span class="xn-text-span">
                                  {{userNote?.lastEditedBy != null ? 'Edited on: ' + (userNote?.lastEditedOn |
                                  date) : userNote?.postedOn }}
                                </span></mat-grid-tile>
                            </mat-grid-list>
                          </ng-container>

                        </div>
                      </div>

                      <div *ngIf="!hasNoNotes" class="xn-center-content xn-top10n table-details">
                        <div>
                          <div align="center">
                            <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />

                          </div>
                          <div>
                            <p class="center">
                              No notes available
                            </p>
                          </div>

                        </div>



                      </div>

                    </div>
                  </div>

                </div>

              </mat-tab>
            </mat-tab-group>
          </div>
          <div *ngIf="isProspect && isLoading">
            <span>Loading...</span>
          </div>
          <div *ngIf="isProspect && !isLoading">
            <br />
            <br />
            <div class="onboarding-user">
              {{profileDetails?.firstName ? (profileDetails?.firstName + ' ' +
              profileDetails?.lastName) : 'User' }}'s hybrid-onboarding journey.
            </div>
            <div class="onboard-line"></div>
            <div class="pending-onboarding">
              <br />
              <div class="stage-box">
                <div class="stage-section">
                  <div class="number-icon">
                    <div class="stage-completed {{ 'complete' | lowercase }}">1</div>
                    <div class="border-line-left"></div>
                  </div>
                  <div class="goal-title">
                    <span>Account Type:</span>
                    <div class="goal-account">{{profileDetails?.accountType ? profileDetails?.accountType : '---' }}
                    </div>
                  </div>
                </div>
                <span class="status-label {{ 'complete' | lowercase }}">Selected</span>
              </div>
              <div class="stage-box">
                <div class="stage-section">
                  <div class="number-icon">
                    <div class="stage-completed {{ 'complete' | lowercase }}">2</div>
                    <div class="border-line-left"></div>
                  </div>
                  <div class="goal-title">
                    <span>Goal:</span>
                    <div class="goal-account">{{profileDetails?.goal.title ? (profileDetails?.goal.title == "Other Goal"
                      ? 'Custom Goal' : profileDetails?.goal.title) : '---' }}</div>
                  </div>

                </div>
                <span class="status-label {{ 'complete' | lowercase }}">Selected</span>
              </div>
              <div class="stage-box">
                <div class="stage-section">
                  <div class="number-icon">
                    <div class="stage-completed {{ 'complete' | lowercase }}">3</div>
                    <div class="border-line-left"></div>
                  </div>

                  <div class="goal-title">
                    <span>Email</span>
                    <div class="network-verification">OTP verification</div>
                  </div>
                </div>
                <span class="status-label {{ 'Verified' | lowercase }}">Verified</span>
              </div>
              <div class="stage-box">
                <div class="stage-section">
                  <div class="number-icon">
                    <div class="stage-completed {{ 'complete' | lowercase }}">4</div>
                    <div class="border-line-left"></div>
                  </div>
                  <div class="goal-title">
                    <span>Phone Number</span>
                    <div class="network-verification">OTP verification</div>
                  </div>
                </div>
                <span class="status-label {{ 'Verified' | lowercase }}">Verified</span>
              </div>
              <div class="stage-box">
                <div class="stage-section">
                  <div class="number-icon">
                    <div class="stage-completed {{ 'complete' | lowercase }}">5</div>
                    <div class="border-line-left"></div>
                  </div>

                  <div class="goal-title">
                    <span>Profile Creation</span>
                    <div class="network-verification">Create password</div>
                  </div>
                </div>
                <span class="status-label {{ 'complete' | lowercase }}">Created</span>
              </div>
              <div class="stage-box">
                <div class="stage-section">
                  <div class="number-icon">
                    <div
                      class="stage-completed {{ profileDetails?.firstName ? ('Verified' | lowercase) : ('Pending' | lowercase) }}">
                      6</div>
                    <div class="border-line-left"></div>
                  </div>
                  <div class="goal-title">
                    <span>Profile Names</span>
                    <div class="network-verification">{{profileDetails?.isNamesEdited ? 'Profile names edited' :
                      'Network name confirmation'}}</div>
                  </div>
                </div>
                <span
                  class="status-label {{ profileDetails?.firstName ? ('Verified' | lowercase) : ('Pending' | lowercase) }}">{{profileDetails?.firstName
                  ? 'Completed' : 'Pending'}}</span>
              </div>
              <div class="stage-box">
                <div class="stage-section">
                  <div class="number-icon">
                    <div class="stage-completed {{ 'Pending' | lowercase }}">7</div>
                    <div class="border-line-left-2"></div>
                  </div>

                  <div class="goal-title">
                    <span>First Deposit</span>
                    <div class="network-verification">Make first deposit</div>
                  </div>
                </div>
                <span class="status-label {{ 'Pending' | lowercase }}">Pending</span>
              </div>
              <div class="pending-stage">
                <div class="stage-box">
                  <div class="stage-section">
                    <div class="number-icon">
                      <div class="stage-completed {{ 'not-completed' | lowercase }}">8</div>
                      <div class="border-line-left"></div>
                    </div>
                    <div class="goal-title">
                      <span>AutoSave</span>
                      <div class="network-verification">Set AutoSave</div>
                    </div>
                  </div>
                  <span class="status-label {{ 'not-completed' | lowercase }}">Not Set</span>
                </div>
                <div class="stage-box">
                  <div class="stage-section">
                    <div class="stage-completed {{ 'not-completed' | lowercase }}">9</div>
                    <div class="goal-title">
                      <span>Identification</span>
                      <div class="network-verification">User KYC</div>
                    </div>
                  </div>
                  <span class="status-label {{ 'not-completed' | lowercase }}">Not provided</span>
                </div>
              </div>


              <!-- <img src="../../../assets/images/no-data.png" class="nodata-img" alt="" />
              {{profileDetails?.firstName ? (profileDetails?.firstName + ' ' +
              profileDetails?.lastName) : 'This user' }} has not yet completed hybrid-onboarding.
              <br />
              <div class="goal-title">
                <span >Account Type:</span> {{profileDetails?.accountType ? profileDetails?.accountType : '---' }}
              </div>
            <div class="goal-title">
              <span >Goal:</span> {{profileDetails?.goal.title ? (profileDetails?.goal.title == "Other Goal" ? 'Custom Goal' : profileDetails?.goal.title) : '---' }}
            </div>
               -->
            </div>
          </div>

        </mat-grid-tile>
      </mat-grid-list>

    </div>
  </div>
  <div *ngIf="isLoading" class="spinner-container fill-container">
    <mat-spinner [diameter]="70" color="accent"></mat-spinner>
  </div>
  <div *ngIf="isModalVisible" class="xn-modal">
    <div class="xn-modal-content">

     <app-disable-profile
       [view]="view"
       [profileDetails]="profileDetails"
        (isOpened)="isOpened($event)"
       [isOpenWindow]="false"></app-disable-profile>

    </div>
  </div>

  <div *ngIf="openAutoSaveCancelWindow" class="xn-modal">
    <div class="xn-modal-content">

  <div class="container">
    <div class="d-flex mb-lg-2">
      <div class="flex-grow-1"><strong *ngIf="hideTitle">Cancel AutoSaves</strong></div>
      <div>
        <a class="pointer"
           *ngIf="hideClose"
           (click)="closeCancelAutoSaveWindow()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
        </a>

      </div>
    </div>
  </div>

      <ng-container
      *ngIf="cancellationReason">
        <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="reasonForm">
              <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">Enter Reason</label>
                <textarea class="form-control" formControlName="reason" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>

              <div class="d-flex">
                <div class="flex-grow-1">
                  <div>
                    <a class="btn btn-outline-primary"
                            (click)="closeCancelAutoSaveWindow()">
                      Cancel
                    </a>
                  </div>
                </div>
                <div>
                    <div>
                      <button class="btn btn-primary"
                       (click)="cancelAutoSave()">
                        Submit
                      </button>
                    </div>

                </div>
              </div>

            </form>
          </div>
        </div>
        </div>
      </ng-container>

      <ng-container
      *ngIf="confirmCancellation">
        <div class="d-flex justify-content-center">
          <div class="">
            <div class="container justify-content-center display-grid">
              <div class="pb-lg-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle fx-size" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                </svg>
              </div>
            </div>

            <div class="text-center pb-lg-5">
              <p class="h4 pb-lg-1">
                Confirm AutoSave Cancellation
              </p>
              <p>
                You cannot undo this action once you to proceed
              </p>
            </div>
          </div>

        </div>

<br/>

        <div class="d-flex">
          <div class="flex-grow-1">
            <div>
              <a class="btn btn-outline-primary"
                 (click)="closeCancelAutoSaveWindow()">
                Cancel
              </a>
            </div>
          </div>
          <div>
            <div>
              <button class="btn btn-primary"
                      (click)="approveAutoSaveCancellation(profileDetails.id)">
                Approve
              </button>
            </div>

          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showSuccessMessage">
        <div align="center">
          <div class="">
            <div class="">
              <div class="pb-lg-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle fx-size" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                </svg>
              </div>
            </div>

            <div class="text-center pb-lg-5">
              <p class="h4 pb-lg-1">
              AutoSave Cancellation success!
              </p>
            </div>
          </div>

          <div class="container">
            <div class="d-flex justify-content-center">
              <button class="btn btn-primary"
                      (click)="closeCancelAutoSaveWindow()">
                Close
              </button>
            </div>

          </div>



        </div>
      </ng-container>


      <ng-container *ngIf="isError">
        <div align="center">
          <div class="">
            <div class="">
              <div class="pb-lg-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle fx-size" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                </svg>
              </div>
            </div>

            <div class="text-center pb-lg-5">
              <p class="h4 pb-lg-1">
                {{showErrorMessage}}
              </p>
            </div>
          </div>

          <div class="container">
            <div class="d-flex justify-content-center">
              <button class="btn btn-primary"
                      (click)="closeCancelAutoSaveWindow()">
                Close
              </button>
            </div>

          </div>



        </div>
      </ng-container>

      <ng-container>
        <div *ngIf="isLoading" align="center">
          <span>Loading...</span>
        </div>
      </ng-container>

    </div>
  </div>
</slideover-user-dash>





