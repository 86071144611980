import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Account, Profile } from '../models';
import {catchError, map, tap} from 'rxjs/operators';
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

 //  private apiUrl = `${environment.nextServerUrl}/api/v1/users`;
  private goalProfileApiUrl = `${environment.serverUrl}/api/v1/users`;
  private apiUrl = `${environment.serverUrl}/api/v1/users`;
  private api = `${environment.serverUrl}/api/v1`;
  private goalSearchUrl = `${environment.serverUrl}/api/v1/advisory-insight`;
  private apiGuest = `${environment.serverUrl}/api/v2/guest/`;
  private apiUserDashUrl = `${environment.serverUrl}/api/v1/orion`;
  private apiUserNotesUrl = `${environment.serverUrl}/api/v1/advisory-note`;

  constructor(private http: HttpClient) {}

  getAll(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}/profiles`, { params });
  }


  getAllUsers(offset: any, max: any, sort: string, order: string, page: number, filter = '', tableFilter: string): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter)
      .set('tableFilter', tableFilter);
    return this.http.get<any>(`${this.apiUserDashUrl}/dashboard-table-data`, { params });
  }

  getAllAdvisors(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter)
    return this.http.get<any>(`${this.apiUserDashUrl}/advisor-dashboard-table-data`, { params });
  }


  getUserDashboardStats(filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUserDashUrl}/dashboard-stats`, { params });
  }

  getAdvisorUserDashbordStats(advisorId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUserDashUrl}/single-advisor-dashboard-stats/${advisorId}`)
  }

  getAdvisorDashboardStats(): Observable<any> {
    return this.http.get<any>(`${this.apiUserDashUrl}/advisor-dashboard-stats`);
  }


  search(filter = ''): Observable<Profile[]> {
    return this.http.get<any>(`${this.apiUrl}/profiles/search/${filter}`);
  }

  searchUsers(filter): Observable<Profile[]> {
    let filterOption = filter ? filter : "''";
    return this.http.get<any>(`${this.apiUserDashUrl}/search-users/${filterOption}`);
  }

  searchAssignedUsers(advisorId, filter): Observable<Profile[]> {
    let filterOption = filter ? filter : "''";
    return this.http.get<any>(`${this.apiUserDashUrl}/advisor-dashboard-search-users/${advisorId}/${filterOption}`);
  }

  searchInstitution(filter): Observable<Account[]> {
  let filterOption = filter ? filter : "''";
    return this.http.get<any>(`${this.apiUrl}/profiles/search-institutions/${filterOption}`);
  }

  searchGoals(filter = ''): Observable<Account[]> {
    return this.http.get<any>(`${this.goalSearchUrl}/search/${filter}`);
  }



  getProfileById(id: string): Observable<any> {
    return this.http.get<any>(`${this.goalProfileApiUrl}/orion/profile/${id}`);
  }

  getAutoSaveByProfileId(id: string): Observable<any> {
    return this.http.get<any>(`${this.api}/autosave/profile-autosaves/${id}`);
  }



  getAdvisorById(offset: any, max: any, sort: string, order: string, page: number, filter = '', id: string): Observable<any> {
    const params: HttpParams = new HttpParams()
    .set('offset', offset)
    .set('max', max)
    .set('sort', sort)
    .set('order', order)
    .set('filter', filter)
    return this.http.get<any>(`${this.apiUserDashUrl}/single-advisor-dashboard-table-data/${id}`, {params});
  }

  getProspectProfileById(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUserDashUrl}/prospect-details/${id}`);
  }

  activateProfile(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/activate/${id}`);
  }

  deactivateProfile(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/deactivate/${id}`);
  }

  getIdImage(username: string): Observable<Blob> {
    return this.http.get(`${environment.serverUrl}/api/v1/guest/idphoto/${username}`, { responseType: 'blob' });
  }

  getPassportImage(username: string): Observable<Blob> {
    return this.http.get(`${environment.serverUrl}/api/v1/guest/passportphoto/${username}`, { responseType: 'blob' });
  }

  uploadVisualIdentities(formData: FormData): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/users/visual-ids`, formData);
  }

  uploadCompanyDocs(formData: FormData): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/users/update-company-docs`, formData);
  }


  updatePersonalInformation(formData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-bio-info`, formData);
  }

  deleteBeneficiary(beneficiaryData): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/users/remove/beneficiary`, beneficiaryData);
  }

  updateProfileIncomeSource(formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-income-source-orion`, formData);
  }

  updateProfileAddress(formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-address-orion`, formData);
  }


  updateProfileBank(formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-bank-details-orion`, formData);
  }

  updateProfileAccountBeneficiary(formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-beneficiary-orion`, formData);
  }

  activateProfileAccount(accountId: string): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/users/enable-account-orion/${accountId}`, {});
  }

  deactivateProfileAccount(accountId: string): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/users/disable-account-orion/${accountId}`, {});
  }

  disableAccountGoal(accountGoalId: string): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/users/disable-account-goal-orion/${accountGoalId}`, {});
  }

  enableAccountGoal(accountGoalId: string): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/users/enable-account-goal-orion/${accountGoalId}`, {});
  }

  setWithdrawLockForAccountGoal(accountGoalId: string): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/accounts/set-withdraw-lock-account-goal/${accountGoalId}`, {});
  }

  removeWithdrawLockForAccountGoal(accountGoalId: string): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/v1/accounts/remove-withdraw-lock-account-goal/${accountGoalId}`, {});
  }


  userEmailSubscription(userId: string, emailSubscription): Observable<any> {
    const params = new HttpParams().set('userId', userId).set('emailSubscription', emailSubscription);
    return this.http.post(`${environment.serverUrl}/api/v1/users/unsubscribe-user-email-orion`, params);
  }

  getAdvisors(): Observable<any> {
    return this.http.get(`${this.apiUrl}/getAdvisors`);
  }

  assignProfileToAdvisor(formData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/assignProfileAdvisor`, formData);
  }

  getUserNotes(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUserNotesUrl}/notes/${id}`);
  }

  saveUserNote(formData: any): Observable<any> {
    return this.http.post(`${this.apiUserNotesUrl}/create-note`, formData);
  }

  editUserComment(formData: any, noteId: string): Observable<any> {
    return this.http.post(`${this.apiUserNotesUrl}/edit-note/${noteId}`, formData);
  }

  getComments(userId: string, noteId: string): Observable<any> {
    return this.http.get(`${this.apiUserNotesUrl}/replies/${userId}/${noteId}`);
  }

  uploadAssignProfileToAdvisor(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/uploadProfileMappingsToXenoStaff`, formData);
  }

  downloadFile(url: string): Observable<Blob> {
    // @ts-ignore
    return this.http.get<Blob>(`${url}`, { responseType: 'blob' });
  }

  canManageBankAccounts(accountId: string, flag: boolean): Observable<any> {
    const params = new HttpParams().set('accountId', accountId).set('canManageBankAccounts', flag.toString());
    return this.http.post(`${environment.serverUrl}/api/v1/users/can-manage-bank-accounts`,
      params);
  }

  // Get user network details
  getUserNetworkDetails(phoneNumber: string): Observable<any> {
    const url = `${this.apiGuest}users/phone-details`;
    return this.http.post<any>(url, {
      phoneNumber,
    });
  }
   // Reset PIN
   resetUSSDPIN(formData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/generate-ussd-pin`, formData);
  }

  removeJointPartner(payload: any): Observable<any> {
     return this.http.post(`${environment.serverUrl}/api/v1/users/remove-partner-from-joint-account`, payload);
   }
  getInstitutionDocument(id: string) {
    return this.http.get(`${environment.serverUrl}/api/v1/guest/orion-institutional-document?accountId=${id}`, { responseType: 'blob' });
  }

  cancelAutoSave(userId: string, formDataJson: any) {
    // return this.http.post(`${environment.serverUrl}/api/v1/profiles/${userId}/autosaves/close`, formDataJson);
    return this.http.post(`${environment.serverUrl}/api/v1/profiles/${userId}/autosaves/close`, formDataJson);
  }
}
