import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CommunicationsComponent} from './pages/communications/communications.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ExportComponent} from './pages/export/export.component';
import {IndividualsComponent} from './pages/individuals/individuals.component';
import {InstitutionsComponent} from './pages/institutions/institutions.component';
import {LoginComponent} from './pages/login/login.component';
import {ProfilesComponent} from './pages/profiles/profiles.component';
import {ProspectsComponent} from './pages/prospects/prospects.component';
import {LttUpdaterComponent} from './pages/ltt-updater/ltt-updater.component';
import {RequestsComponent} from './pages/requests/requests.component';
import {SingleAccountComponent} from './pages/single-account/single-account.component';
import {SingleGoalComponent} from './pages/single-goal/single-goal.component';
import {SingleProfileComponent} from './pages/single-profile/single-profile.component';
import {SingleRequestComponent} from './pages/single-request/single-request.component';
import {UsersComponent} from './pages/users/users.component';
import {AuthComponent} from './auth/auth.component';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {AuthenticationService} from './services';
import {RolesComponent} from './pages/roles/roles.component';
import {AccessLevelsComponent} from './pages/access-levels/access-levels.component';
import {ViewCapabilitiesComponent} from './modules/user/view-capabilities/view-capabilities.component';
import {ViewStaffComponent} from './modules/user/view-staff/view-staff.component';
import {TasksComponent} from '@orion/pages/tasks/tasks.component';
import {ReferralsComponent} from '@orion/pages/referrals/referrals.component';
import {DepartmentsComponent} from '@orion/pages/departments/departments.component';
import {OnboardingComponent} from '@orion/pages/onboarding/onboarding.component';
import {ActivateUssdAccountComponent} from '@orion/pages/activate-ussd-account/activate-ussd-account.component';
import {BulkSmsComponent} from '@orion/pages/bulk-sms/bulk-sms.component';
import {BulkEmailsWithBatchComponent} from '@orion/pages/bulk-emails-with-batch/bulk-emails-with-batch.component';
import {BulkEmailsComponent} from '@orion/pages/bulk-emails/bulk-emails.component';
import {AccountMergeComponent} from '@orion/pages/account-merge/account-merge.component';
import {LctrDashboardComponent} from '@orion/pages/lctr-dashboard/lctr-dashboard.component';
import { UsersDashboardComponent } from './pages/users-dasboard/users-dashboard.component';
import { AdvisoryUploadComponent } from './pages/advisory-upload/advisory-upload.component';
import { AdvisorDashboardComponent } from './pages/advisor-dashboard/advisor-dashboard.component';
import { MyUsersComponent } from './pages/assigned-users/assigned-users.component';
import {AuthGuard} from "@orion/helpers/auth.guard";
import { InitializerComponent } from './components/initializer/initializer.component';
import {DepositConfirmationComponent} from "@orion/pages/deposit-confirmation/deposit-confirmation.component";
import {ReferralNotificationComponent} from "@orion/pages/referral-notification/referral-notification.component";

const routes: Routes = [
  {path: '', redirectTo: '/setting-dashboard', pathMatch: 'full'},
  // {
  //   path: '',
  //   component: AuthComponent,
  //   children: [{path: 'login', component: LoginComponent}],
  // },
  {path: 'setting-dashboard', component: InitializerComponent, canActivate: [AuthGuard]},
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'user-dashboard', component: UsersDashboardComponent},
      {path: 'access-levels', component: AccessLevelsComponent},
      // {path: 'profiles', component: ProfilesComponent},
      // {path: 'prospects', component: ProspectsComponent},
      {path: 'upload-advisory', component: AdvisoryUploadComponent},
      {path: 'advisor-dashboard', component: AdvisorDashboardComponent},
      {path: 'assigned-users', component: MyUsersComponent},
      {path: 'ltt-updater', component: LttUpdaterComponent},
      {path: 'single-profile', component: SingleProfileComponent},
      {path: 'institutions', component: InstitutionsComponent},
      {path: 'referrals', component: ReferralsComponent},
      {path: 'individuals', component: IndividualsComponent},
      {path: 'single-account', component: SingleAccountComponent},
      {path: 'single-goal', component: SingleGoalComponent},
      {path: 'requests', component: RequestsComponent},
      {path: 'single-request', component: SingleRequestComponent},
      {path: 'communications', component: CommunicationsComponent},
      {path: 'users', component: UsersComponent},
      {path: 'exports', component: ExportComponent},
      {path: 'roles', component: RolesComponent},
      {path: 'departments', component: DepartmentsComponent},
      {path: 'capabilities', component: ViewCapabilitiesComponent},
      {path: 'staff', component: ViewStaffComponent},
      {path: 'tasks', component: TasksComponent},
      {path: 'bulk-sms', component: BulkSmsComponent},
      {path: 'bulk-emails-with-batch', component: BulkEmailsWithBatchComponent},
      {path: 'bulk-emails', component: BulkEmailsComponent},
      {path: 'tasks', component: TasksComponent},
      {path: 'on-boarding', component: OnboardingComponent},
      {path: 'activate-ussd-account', component: ActivateUssdAccountComponent},
      {path: 'account-merge', component: AccountMergeComponent},
      {path: 'lctr-dashboard', component: LctrDashboardComponent},
      {path: 'deposit-confirmation', component: DepositConfirmationComponent},
      {path: 'referral-activity',
        loadComponent: () => import('@orion/pages/referral-notification/referral-notification.component')
          .then(m => m.ReferralNotificationComponent)},
    ],
  },
  { path: 'partners', loadChildren: () => import('./modules/partners/partners.module').then(m => m.PartnersModule) },
  {
    path: 'goals',
    canActivate: [AuthenticationService],
    loadChildren: () => import('./modules/goal/goal.module').then(m => m.GoalModule)
  },
];

// @ts-ignore
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
