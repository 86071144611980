<div class="desktop-only">
  <ng-container *ngIf="selectCountry" >
    <app-country-selector >

    </app-country-selector>
  </ng-container>
  <router-outlet></router-outlet>
</div>


<div class="non-desktop-message">
  <div class="card mx-2">
    <div class="card-body">
      <br/>
      <div class="text-center">
        <div class="d-flex justify-content-center mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
          </svg>
        </div>
        <h1 class="mb-2" style="font-size: 20px">Access Denied</h1>
        <p>This application is only available on desktop devices</p>
        <div class="mt-4">
          <a href="mailto:support@myxeno.com" class="btn btn-primary">Contact Admin</a>
        </div>
      </div>
      <br/>
    </div>
  </div>


</div>
