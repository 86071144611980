// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  serverUrl: 'http://localhost:8080',
  //  serverUrl: 'https://api.myxeno.com',
   //serverUrl: 'http://192.168.1.108:8282',
  serverUrl: 'https://bf.xn-io.com/leg',
  // nextServerUrl: 'https://3089-197-211-58-130.ngrok-free.app',
  // serverUrl: 'https://10.0.0.94:8082/leg',
  //  serverUrl: 'https://demoapi.myxeno.com'
  // serverUrl: 'https://api.myxeno.com/'
  // serverUrl: 'https://demoapi.myxeno.com'
  // serverUrl: 'https://ultima.xn-io.com'
  // serverUrl: 'https://ultima-ke.xn-io.com'
  // serverUrl: 'http://192.168.0.107:8080',
  // serverUrl: 'https://ultima259.myxeno.com'
//  serverUrl: 'http://192.168.1.10:8080'
// serverUrl: 'https://bf.xn-io.com/leg',
  //  serverUrl: 'https://ultima259.myxeno.com'
//  serverUrl: 'http://192.168.0.101:8080'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
